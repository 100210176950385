import Image from "@src/shared/image/Image";
import { Link } from "gatsby";
import styled, { css } from "styled-components";

export const Root = styled.div`
    width: 320px;
    margin-bottom: 3px;
    background: white;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-decoration: none;
    border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    margin-right: 5%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 20px;
    }

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        width: 324px;
        margin-right: 30px;
    }

    @media all and (orientation: landscape) and (max-width: ${({ theme }) => theme.breakpoints.md.value - 1}px) {
        margin: 0 10px;
    }
`;

export const LinkWrapper = styled(Link)`
    background: white;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-decoration: none;
`;

export const Title = styled.h3<{ isUppercase: boolean }>`
    height: 42px;

    ${({ isUppercase }) =>
        isUppercase &&
        css`
            text-transform: uppercase;
        `}
`;

export const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 75%;
`;

export const VehicleImage = styled(Image)<{ defaultImage?: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    align-content: center;

    ${({ defaultImage }) =>
        defaultImage &&
        css`
            img {
                object-fit: cover;
            }
        `}
`;

export const Content = styled.div`
    padding: 0 15px 15px 15px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
    }
`;

export const Stats = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin-bottom: 10px;
    flex-wrap: wrap;
    gap: 10px;
`;

export const Stat = styled.div`
    font-size: 14px;
    line-height: 20px;
`;

export const StatLabel = styled.h4`
    margin: 0;
    font-weight: 400;
`;

export const StatValue = styled.span`
    font-weight: 700;
`;

export const DetailsLink = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.porscheBankBlue};
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    justify-content: center;
    font-size: 19px;
    padding: 15px 0;
    text-transform: uppercase;
`;
