import LeftArrow from "./arrow-left.svg";
import CarAvatar from "./carAvatar.png";
import CheckmarkGrey from "./checkmarkGrey.svg";
import CheckmarkWhite from "./checkmarkWhite.svg";
import Close from "./close.svg";
import Expand from "./expand.svg";
import InfoIcon from "./infoIcon.svg";
import Location from "./location.svg";

const Images = {
    CarAvatar,
    CheckmarkGrey,
    CheckmarkWhite,
    InfoIcon,
    Location,
    LeftArrow,
    Expand,
    Close,
};

export default Images;
